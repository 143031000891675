import { useNavigate } from "react-router-dom";
import FooterNavigation from "../components/FooterNavigation";
import { useProductItems, usePickupAddresses, useCurrentUser } from "../swr/index";
import { useState, useEffect, useRef } from "react";
import AddressAutoComplete from "../components/AddressAutoComplete";
import { useSWRConfig } from "swr";
import {
  API_URL,
  POST_FETCH_OPTIONS,
  GET_FETCH_OPTIONS,
  PAYMENT_METHOD,
  PAYMENT_METHOD_TITLE,
} from "../common/constants";
import SelectCity from "../components/small/SelectCity";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TimePicker from "../components/UI/TimePicker";

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

export default function PageOrderPage() {
  const { mutate } = useSWRConfig();
  const navigate = useNavigate();
  const { products, isLoading, isError } = useProductItems();
  const { data: userData } = useCurrentUser();

  const page = 1;
  const limit = 99;
  const type = "Pickup";
  const { pickupAddresses, isLoading2, isError2 } = usePickupAddresses(page, limit, type);

  const [addingProduct, setAddingProduct] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState([]);

  const [showPredictions, setShowPredictions] = useState(false);

  const [subtotal, setSubtotal] = useState(0);

  const [pickupAddressId, setPickupAddressId] = useState("");
  const [pickupAddress, setPickupAddress] = useState({});
  const [deliveryAddress, setDeliveryAddress] = useState("");

  const [isReview, setIsReview] = useState(true);
  const [deliveryFee, setDeliveryFee] = useState(null);

  const [deliveryTiming, setDeliveryTiming] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const aLittleLater = moment(new Date().addHours(1)).format("HH:mm");

  const [time, onTimeChange] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    if (selectedProducts.length) {
      setSelectedQuantities([...selectedQuantities, 1]);
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (selectedQuantities.length) {
      const newTotal = getSubtotal();
      setSubtotal(newTotal);
    }
  }, [selectedQuantities]);

  if (isLoading) return "Loading...";
  if (isError) return "Error...";

  if (isLoading2) {
    return "Loading...";
  }
  if (isError2) {
    return "Error...";
  }

  const handleSignUp = async (e) => {
    setErrorMessage("");
    e.preventDefault();
    const formData = new FormData(e.target);
    const keys = [
      "invoice_number",
      "customer_name",
      "customer_phone_number",

      "pickup_address_id",

      "delivery_block",
      "delivery_street",
      "delivery_lane",
      "delivery_floor",
      "delivery_apartment",
      "delivery_house",
      "delivery_additional_directions",
    ];

    let data = {};
    keys.forEach((key) => (data[key] = formData.get(key)));
    // const deliveryCompany = userData.delivery_companies?.find(item => true)
    const deliveryCompany = userData.delivery_company.name;
	console.log("userdata", userData);

    if (!deliveryCompany) {
      setErrorMessage("Please set a delivery company in your settings page.");
      toast.error("Please set a delivery company in your settings page.");
      return;
    } else if (!deliveryTiming) {
      setErrorMessage("Please select a delivery time.");
      toast.error("Please select a delivery time.");
      return;
    } else if (!pickupAddress) {
      setErrorMessage("Please select a pickup address.");
      toast.error("Please select a pickup address.");
      return;
    } else if (!deliveryAddress) {
      setErrorMessage("Please select a delivery address.");
      toast.error("Please select a delivery address.");
      return;
    } else if (!data.invoice_number) {
      setErrorMessage("Please enter an invoice number.");
      toast.error("Please enter an invoice number.");
      return;
    } else if (!data.customer_name) {
      setErrorMessage("Please enter a customer name.");
      toast.error("Please enter a customer name.");
      return;
    } else if (!data.customer_phone_number) {
      setErrorMessage("Please enter a customer phone number.");
      toast.error("Please enter a customer phone number.");
      return;
    } else if (data.customer_phone_number.length !== 8) {
      setErrorMessage("Phone number must be 8 digits.");
      toast.error("Phone number must be 8 digits");
      return;
    } else if (!data.delivery_block) {
      setErrorMessage("Please enter a delivery block.");
      toast.error("Please enter a delivery block.");
      return;
    } else if (!data.delivery_street) {
      setErrorMessage("Please enter a delivery street.");
      toast.error("Please enter a delivery street.");
      return;
    } else if (selectedProducts.length < 1) {
      setErrorMessage("Please add an item to this order.");
      toast.error("Please add an item to this order.");
      return;
    } else if (!paymentMethod) {
      setErrorMessage("Please select a customer payment method for this order.");
      toast.error("Please select a customer payment method for this order.");
      return;
    } else if (!data.delivery_house) {
      setErrorMessage("Please enter a delivery house #.");
      toast.error("Please enter a delivery house #.");
      return;
    }

    let timestamp = null;

    if (deliveryTiming === "LATER") {
      if (!time || !date) {
        setErrorMessage("Please select a delivery date for this order.");
        toast.error("Please select a delivery date for this order.");
        return;
      }
      const dateString = `${date} ${time}`;
      timestamp = moment(dateString, "YYYY-MM-DD h:m A");
      const now = moment();

      if (timestamp.diff(now, "hours", true) < 1) {
        setErrorMessage("Please set a delivery time at least 1 hour later.");
        toast.error("Please set a delivery time at least 1 hour later.");
        return;
      }
    } else {
      //INSTANT delivery
      timestamp = moment();
    }

    //Calculates delivery fee
    //TODO: replace with zone
    const zones = await fetch(API_URL.DELIVERY_ZONE, GET_FETCH_OPTIONS).then((res) => res.json());
    const final = zones.find((zone) => zone.cities?.includes(deliveryAddress));


    if (final) {
      setDeliveryFee(final.fee);
      setIsReview(false);
    } else {
      setErrorMessage("This delivery city doesn't have a delivery fee set!");
      toast.error("This delivery city doesn't have a delivery fee set!");
    }

    if (isReview) {
      return;
    }

    data["pickup_address_id"] = parseInt(data["pickup_address_id"]);
    data.pickup_city = pickupAddress.city;
    data.delivery_city = deliveryAddress;
    data.delivery_street = data.delivery_street || "";
    data.delivery_block = parseInt(data.delivery_block) || 0;
    data.delivery_lane = parseInt(data.delivery_lane) || 0;
    data.delivery_floor = parseInt(data.delivery_floor) || 0;
    data.delivery_apartment = parseInt(data.delivery_apartment) || 0;
    data.delivery_house = parseInt(data.delivery_house) || 0;

    data.subtotal = subtotal;
    data.products = selectedProducts.map((product, index) => {
      return { ...product, quantity: selectedQuantities[index] };
    });
    data.delivery_fee = deliveryFee;
    data.pickup_lat = pickupAddress.place_lat;
    data.pickup_lng = pickupAddress.place_lng;
    data.delivery_date = timestamp.utc().format("YYYY-MM-DD HH:mm:ss");
    data.payment_method = paymentMethod;

    data.delivery_company_name = deliveryCompany;

    //TODO validation error check from backend
    mutate([API_URL.ORDERS, "?page=1&limit=50"], async (existing) => {
      const newOrder = await fetch(API_URL.ORDERS, POST_FETCH_OPTIONS(data)).then((res) =>
        res.json()
      );
      return [...existing, newOrder];
    });

    navigate("/dashboard");
  };

  const getSubtotal = () => {
    let subtotal = 0;
    selectedProducts.forEach((product, index) => {
      const quantity = selectedQuantities[index];
      const productSubtotal = parseFloat(product.price) * quantity;
      subtotal += productSubtotal;
    });
    return subtotal;
  };

  const addItem = (item) => {
    if (selectedProducts.includes(item)) {
      setAddingProduct(false);
      return;
    }
    setSelectedProducts([...selectedProducts, item]);
    setAddingProduct(false);
  };

  const calculatePickupDeliveryFee = (id) => {
    const address = pickupAddresses.find((address) => address.id == id);
    setPickupAddress(address);
    setPickupAddressId(id);
    setDeliveryFee(null);
    setIsReview(true);
  };

  const calculateDestinationDeliveryFee = (value) => {
    setDeliveryAddress(value);
    setDeliveryFee(null);
    setIsReview(true);
  };

  return (
    <div className="container">
      <ToastContainer />
      <h1 className="is-size-1 has-text-centered my-5">Place Order</h1>
      <FooterNavigation activeTab={"order"} />
      <div className="my-6" />
      <div className="max-width-md mx-auto" onClick={() => setShowPredictions(false)}>
        {errorMessage && <p className="has-text-danger">{errorMessage}</p>}
        <form onSubmit={handleSignUp}>
          <label className="label is-size-5">
            Invoice # <span className="has-text-danger">*</span>
          </label>
          <input name="invoice_number" className="input mb-4" type="text" />

          <label className="label is-size-5">
            Customer name <span className="has-text-danger">*</span>
          </label>
          <input name="customer_name" className="input mb-4" type="text" />

          <label className="label is-size-5">
            Customer phone number <span className="has-text-danger">*</span>
          </label>
          <input name="customer_phone_number" className="input mb-4" type="text" />

          <hr />

          <label className="label is-size-5">
            Add items to order <span className="has-text-danger">*</span>
          </label>
          <button
            onClick={(e) => {
              e.preventDefault();
              setAddingProduct(true);
            }}
            className="button is-info full-rounded is-pulled-right"
          >
            +
          </button>

          <div className="box">
            <div className="columns is-flex-direction-row is-flex is-gapless">
              <div className="column is-one-quarter">
                <p className="has-text-weight-bold">Item Name</p>
              </div>
              <div className="column is-one-quarter">
                <p className="has-text-weight-bold">Unit Price</p>
              </div>
              <div className="column is-one-quarter">
                <p className="has-text-weight-bold">Quantity</p>
              </div>
              <div className="column is-one-quarter">
                <p className="has-text-weight-bold">Subtotal</p>
              </div>
            </div>
            {selectedProducts.length ? (
              selectedProducts.map((product, index) => (
                <div key={product.id} className="columns is-flex-direction-row is-flex is-gapless">
                  <div className="column is-one-quarter">
                    <p>{product.name}</p>
                  </div>
                  <div className="column is-one-quarter">
                    <p>{Math.abs(product.price).toFixed(3)} KWD</p>
                  </div>
                  <div className="column is-one-quarter">
                    <input
                      value={selectedQuantities[index] || 1}
                      onChange={(e) => {
                        const mutate = selectedQuantities.slice();
                        mutate[index] = e.target.value;
                        setSelectedQuantities(mutate);
                      }}
                      type="number"
                      min="0"
                      placeholder="Quantity"
                      style={{ width: "60px" }}
                    />
                  </div>
                  <div className="column is-one-quarter">
                    <p>
                      {Math.abs(parseFloat(product.price) * selectedQuantities[index]).toFixed(3)}{" "}
                      KWD
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        const indexOf = selectedProducts.indexOf(product);
                        let newProducts = selectedProducts.slice();
                        let newQuantities = selectedQuantities.slice();
                        newProducts.splice(indexOf, 1);
                        newQuantities.splice(indexOf, 1);
                        setSelectedProducts(newProducts);
                        setSelectedQuantities(newQuantities);
                      }}
                    >
                      X
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className="has-text-centered has-text-grey is-italic">
                <p>Add an item to this order</p>
              </div>
            )}
            <div className="columns mt-4">
              <p className="column is-two-thirds">
                <span className="has-text-weight-bold">Delivery Fee:</span>{" "}
                {deliveryFee ? (
                  <span>{Math.abs(deliveryFee).toFixed(3)} KWD</span>
                ) : (
                  <em>Estimated on review</em>
                )}{" "}
              </p>
              <p className="column is-one-third has-text-weight-bold">
                Total:{" "}
                {deliveryFee && (
                  <span>
                    {Math.abs(parseFloat(deliveryFee) + parseFloat(subtotal)).toFixed(3)} KWD
                  </span>
                )}
              </p>
            </div>
          </div>

          <div className={`modal ${addingProduct ? "is-active" : ""}`}>
            <div className="modal-background"></div>
            <div className="modal-content">
              <div className="box">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setAddingProduct(false);
                  }}
                  className="is-pulled-right is-large is-clickable"
                  aria-label="close"
                >
                  X
                </button>
                <h2 className="is-size-4 mb-5 has-text-weight-bold has-text-centered">
                  Available Products
                </h2>
                <table className="table mx-auto is-borderless">
                  <thead>
                    <tr>
                      <th align="center">Product name</th>
                      <th align="center">Price</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product) => (
                      <tr key={product.id} className="bottom-border space">
                        <td align="center" className="is-vcentered">
                          {product.name}
                        </td>
                        <td align="center" className="is-vcentered">
                          {Math.abs(product.price).toFixed(3)} KWD{" "}
                        </td>
                        <td className="">
                          <span
                            onClick={() => addItem(product)}
                            className="button has-background-grey-light button-width-sm has-text-weight-bold"
                          >
                            Add
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                setAddingProduct(false);
              }}
              className="modal-close is-large"
              aria-label="close"
            ></button>
          </div>

          <hr />

          <label className="label is-size-5">
            Customer payment method <span className="has-text-danger">*</span>
          </label>
          <div onChange={(e) => setPaymentMethod(e.target?.value)}>
            <input type="radio" value={PAYMENT_METHOD.ONLINE} id="online" name="payment" />
            <label htmlFor="online" className="ml-2 mr-4">
              {PAYMENT_METHOD_TITLE[PAYMENT_METHOD.ONLINE]}
            </label>
            <input type="radio" value={PAYMENT_METHOD.CASH} id="cash" name="payment" />
            <label htmlFor="cash" className="ml-2">
              {PAYMENT_METHOD_TITLE[PAYMENT_METHOD.CASH]}
            </label>
          </div>

          <hr />

          <label className="label is-size-5">
            Pickup address <span className="has-text-danger">*</span>
          </label>
          <div className="select is-fullwidth mb-5">
            <select
              value={pickupAddressId}
              onChange={(e) => calculatePickupDeliveryFee(e.target.value)}
              name="pickup_address_id"
            >
              <option value="">Select a pickup address</option>
              {pickupAddresses &&
                pickupAddresses.map((address) => (
                  <option key={address.place_id} value={address.id}>
                    {address.place_name} @ {address.place_address}
                  </option>
                ))}
            </select>
          </div>

          <label className="label is-size-5">
            Delivery address <span className="has-text-danger">*</span>
          </label>
          <div className="mb-1">
            <SelectCity
              value={deliveryAddress}
              onSelect={(city) => calculateDestinationDeliveryFee(city)}
            />
          </div>
          <div className="is-flex mb-1">
            <input
              className="input mr-2"
              name="delivery_block"
              type="number"
              min="0"
              placeholder="Block # (required)"
            />
            <input
              className="input mr-2"
              name="delivery_street"
              type="text"
              placeholder="Street (required)"
            />
            <input
              className="input"
              name="delivery_lane"
              type="number"
              min="0"
              placeholder="Lane (Avenue/Jaddah) # "
            />
          </div>
          <div className="is-flex">
            <input
              className="input mr-2"
              name="delivery_house"
              type="number"
              min="0"
              placeholder="House # (required)"
            />
            <input
              className="input mr-2"
              name="delivery_floor"
              type="number"
              min="0"
              placeholder="Floor #"
            />
            <input
              className="input"
              name="delivery_apartment"
              type="number"
              min="0"
              placeholder="Apartment #"
            />
          </div>

          <label className="mt-4 label is-size-5">Additional Delivery Directions (optional)</label>
          <textarea
            className="mt-2 textarea is-fullwidth"
            name="delivery_additional_directions"
          ></textarea>

          <hr />

          <label className="label is-size-5">
            Delivery time <span className="has-text-danger">*</span>
          </label>
          <div onChange={(e) => setDeliveryTiming(e.target?.value)}>
            <input type="radio" value="NOW" id="now" name="timing" />
            <label htmlFor="now" className="ml-2 mr-4">
              Instant Delivery Request
            </label>
            <input type="radio" value="LATER" id="later" name="timing" />
            <label htmlFor="later" className="ml-2">
              Scheduled Delivery
            </label>
          </div>

          {deliveryTiming === "LATER" && (
            <div className="is-flex my-2">
              <input
                onChange={(e) => setDate(e.target?.value)}
                min={moment().format("YYYY-MM-DD")}
                type="date"
                id="date"
                name="date"
              />
              <span className="mx-3">@</span>
              <TimePicker onChange={onTimeChange} />
            </div>
          )}

          <hr />
          {/*<AddressAutoComplete setShowPredictions={setShowPredictions} showPredictions={showPredictions} />*/}
          {deliveryFee ? (
            <input
              className="button is-info is-fullwidth mt-2"
              type="submit"
              value="Submit Order"
            />
          ) : (
            <input
              className="button is-warning is-fullwidth mt-2"
              type="submit"
              value="Review Order"
            />
          )}
        </form>
      </div>
      <div className="mb-6 pb-6" />
    </div>
  );
}
