import React from "react";

export const PageLayout = ({
  title,
  children,
  activeTab,
  NavigationComponent,
}) => {
  return (
    <div className="container is-fluid">
      <h1 className="is-size-2 has-text-centered has-text-weight-bold mb-3">
        {title}
      </h1>
      {NavigationComponent && <NavigationComponent activeTab={activeTab} />}
      {children}
    </div>
  );
};

// const styles = {
//   page_container: {
//     border: "1px solid #cccccc",
//     borderRadius: "5px",
//     backgroundColor: "#fdfdfd", // Fixed the property name
//     width: "100%",
//     padding: "20px",
//     boxShadow: "0 2px 2px rgba(0, 0, 0, 0.2)",
//     display: "flex",
//     flexDirection: "column", // Fixed the property name
//   },
// };
