import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";

const SearchField = ({ placeholder, value, onChange }) => {
  return (
    <TextField
      fullWidth
      size="small"
      margin="dense"
      placeholder={placeholder || "Search..."}
      value={value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search size={20} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
