import { Outlet, useNavigate } from 'react-router-dom'
import { USER_ROLE } from '../../helpers/constants'
import { useEffect } from 'react'
import { Logo } from '../../helpers/logo'

export default function AdminCheckAuthAndNavigate() {

	const navigation = useNavigate();

	useEffect(() => {
		if (localStorage.getItem('role') !== USER_ROLE.ADMIN) {
			navigation('/admin')
		}
	}, [navigation])

	return (
		<>
			<Logo />
			<div className="m-3 mt-5 mb-5">
				<Outlet />
			</div>
		</>
	)
}