import { useState } from "react";
import AdminPagesNavigation from "./navigation/AdminPagesNavigation";
import { Tag } from "antd";
import { PageLayout } from "../helpers/PageLayout";
import { Card, CardContent, Box, Typography } from "@mui/material";
import {
  useFetchBusinesses,
  useFetchDeliveryCompanies,
  useFetchAdminDashboardPageOrders,
} from "../swr/hooks";
import LoadingSpinner from "../helpers/LoadingSpinner";
import Button from "../helpers/Button";
import DateRangeField from "../helpers/DateRangeField";
import DropDownField from "../helpers/DropDownField";
import SearchField from "../helpers/SearchField";
import Table from "../helpers/Table";

export default function AdminDashboardPage() {
  const [selectedRange, setSelectedRange] = useState("today");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedBusinessId, setSelectedBusinessId] = useState("All");
  const [selectedCompanyId, setSelectedCompanyId] = useState("All");
  const [searchText, setSearchText] = useState("");

  const {
    data: businessesData,
    isLoading: fetchBusinessesLoading,
    isError: fetchBusinessesError,
  } = useFetchBusinesses();

  const {
    data: deliveryCompaniesData,
    isLoading: fetchDeliveryCompaniesLoading,
    isError: fetchDeliveryCompaniesError,
  } = useFetchDeliveryCompanies();

  const queryString = {
    selectedRange,
    fromDate,
    toDate,
    selectedBusinessId,
    selectedCompanyId,
    searchText,
  };

  const {
    orders,
    orderCount,
    isLoading: fetchOrdersLoading,
    isError: fetchOrdersError,
  } = useFetchAdminDashboardPageOrders(queryString);

  const handleClearFilters = () => {
    setSelectedRange("today");
    setFromDate("");
    setToDate("");
    setSelectedBusinessId("All");
    setSelectedCompanyId("All");
    setSearchText("");
  };

  if (fetchBusinessesLoading || fetchDeliveryCompaniesLoading) {
    //Not checking order data loading as its appied on table.
    return <LoadingSpinner />;
  }
  if (fetchBusinessesError || fetchDeliveryCompaniesError || fetchOrdersError) {
    const errorMessage = fetchBusinessesError
      ? "Businesses Data Error"
      : fetchDeliveryCompaniesError
      ? "Delivery Companies Data Error"
      : "Orders Data Error";

    return (
      <PageLayout
        title="Admin Dashboard"
        activeTab="admin-dashboard"
        NavigationComponent={AdminPagesNavigation}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" component="h3">
              <strong>Error! </strong>
              <em>{errorMessage}</em>
            </Typography>
          </CardContent>
        </Card>
      </PageLayout>
    );
  }

  return (
    <>
      <PageLayout
        title="Admin Dashboard"
        activeTab="admin-dashboard"
        NavigationComponent={AdminPagesNavigation}
      >
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
                <strong>Orders Management </strong>
                <em>(Filters)</em>
              </Typography>
              <Button text="Clear" color={Button.buttonColors.RED} onClick={handleClearFilters} />
            </Box>
            <DateRangeField
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              allowedRanges={[
                "today",
                "yesterday",
                "last7days",
                "last30days",
                "thismonth",
                "lastmonth",
                "customrange",
              ]}
            />
            <DropDownField
              label="Select Business"
              value={selectedBusinessId}
              onChange={(e) => setSelectedBusinessId(e.target.value)}
              options={businessesData}
              valueKey="id"
              labelKey="business_name"
            />
            <DropDownField
              label="Select Delivery Company"
              value={selectedCompanyId}
              onChange={(e) => setSelectedCompanyId(e.target.value)}
              options={deliveryCompaniesData}
              valueKey="id"
              labelKey="name"
            />
            <SearchField
              placeholder="Search Orders..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </CardContent>
        </Card>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <DisplayTable />
          </CardContent>
        </Card>
      </PageLayout>
    </>
  );

  // ** Ant Design Table Component **
  function DisplayTable() {
    const tableColumns = [
      { title: "Order #", dataIndex: "orderNumber", key: "orderNumber", align: "center" },
      { title: "Business Name", dataIndex: "business", key: "business", align: "center" },
      {
        title: "Delivery Company",
        dataIndex: "deliveryCompany",
        key: "deliveryCompany",
        align: "center",
      },
      {
        title: "Payment Method",
        dataIndex: "paymentMethod",
        key: "paymentMethod",
        align: "center",
      },
      { title: "Subtotal", dataIndex: "subtotal", key: "subtotal", align: "center" },
      { title: "Delivery Fee", dataIndex: "deliveryFee", key: "deliveryFee", align: "center" },
      { title: "Total", dataIndex: "total", key: "total", align: "center" },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        align: "center",

        render: (status) => {
          const statusColors = {
            CREATED: "processing", // Standard Blue for ongoing processes
            ACCEPTED: "green", // Light Green for positive confirmation
            DELIVERING: "orange", // Standard Orange for in-progress attention
            DELIVERED: "green", // Dark Green to differentiate from Accepted
            FAILED: "error", // Standard Red for failures
            CANCELED: "default", // Standard Gray for inactive status
          };

          return <Tag color={statusColors[status] || "default"}>{status}</Tag>;
        },
      },
    ];
    return (
      <Table
        columns={tableColumns}
        data={orders}
        loading={fetchOrdersLoading}
        sumColumns={["subtotal", "deliveryFee", "total"]}
      />
    );
  }
}
