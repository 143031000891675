// icons.js (A centralized file for all MUI icons)
import StorefrontIcon from "@mui/icons-material/Storefront";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import PublicIcon from "@mui/icons-material/Public";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

// Export icons as an object
const Icons = {
  StorefrontIcon,
  DeliveryDiningIcon,
  GlobeIcon: PublicIcon,
  MoneyBagIcon: MonetizationOnIcon,
};

export default Icons;
