import { useDeliveryCompanies } from "../../swr/admin";

export default function SelectDeliveryCompany({ onSelect, value = "" }) {
  const { data, isLoading, isError } = useDeliveryCompanies();

  if (isLoading) return "Loading...";
  if (isError) return "Error...";

return (
	<div className="select is-fullwidth">
	  <select value={value} onChange={(e) => onSelect(e.target.value)} name="company">
		<option value="">Select a delivery company</option>
		{data?.deliveryCompanies?.map((company) => (
		  <option key={company.id} value={company.id}>
			{company.name}
		  </option>
		))}
	  </select>
	</div>
  );
}
