import TableFilterOptions from './TableFilterOptions';
import styles from './common/styles/Table.module.css';
import Pagination from '../small/Pagination'
import { useState } from 'react'
import { useOrders } from '../../swr/index'
import { LoadingSpinner } from '../common/components/Common.components.export';
//import './style.css';
import { Link } from 'react-router-dom';
import moment from 'moment';


import { PAYMENT_METHOD_TITLE, DELIVERY_STATUS } from '../../common/constants'
import DeliveryStatusFormat from '../UI/DeliveryStatusFormat'

const OrderTable = ({ limit = 100, orderSource, currentPage, setCurrentPage }) => {
    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')
    const { orders, orderCount, isLoading, isError } = useOrders(currentPage, limit, '', fromDate, toDate, '', '', orderSource)

    if (isLoading) return <LoadingSpinner />
    if (isError) return 'Error...'

    const subtotalSum = orders?.reduce((sum, order) => sum + parseFloat(order.status !== DELIVERY_STATUS.CANCELED ? order.subtotal : 0), 0)
    const deliverySum = orders?.reduce((sum, order) => sum + parseFloat(order.status !== DELIVERY_STATUS.CANCELED ? order.delivery_fee : 0), 0)
    const compensatedDeliverySum = orders?.reduce((sum, order) => sum + parseFloat(order.status !== DELIVERY_STATUS.CANCELED ? order.compensated_delivery_fee : 0), 0)

    return (
        <>
            <TableFilterOptions
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
            />
            <div className={styles.tableContainer}>
                <table className={styles.data_table}>
                    <thead>
                        <tr>
                            <th>Invoice #</th>
                            <th>Order Details</th>
                            <th>Customer Name</th>
                            <th>Email / Phone #</th>
                            <th>Payment Method</th>
                            <th>Subtotal</th>
                            <th>Delivery Fee</th>
                            <th>Total</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders?.map(order => (
                            <tr key={order.id}>
                                <td>
                                    {order.order_source === "SHOPIFY" && (
                                        <img
                                            src="/shopify-bag-icon.png"
                                            alt="Shopify Logo"
                                            style={{
                                                width: "30px",
                                                // marginRight: "0px",
                                                verticalAlign: "middle",
                                                position: "relative",
                                                top: "-1.5px"
                                            }}
                                        />
                                    )}
                                    <Link to={`/order/${order.id}`}>{order.invoice_number}</Link>
                                </td>
                                <td>{order.item_ids ? order.item_ids.length : 0} items</td>
                                <td>{order.customer_name}</td>
                                <td className={styles.email_column}>{order.customer_phone_number ? order.customer_phone_number : order.customer_email}</td>
                                <td>{PAYMENT_METHOD_TITLE[order.payment_method]}</td>
                                <td>{Math.abs(order.subtotal).toFixed(2)}<br />{order.currency}</td>
                                <td>{Math.abs(order.delivery_fee).toFixed(2) || 0}<br />{order.currency}</td>
                                <td>{Math.abs(parseFloat(order.delivery_fee) + parseFloat(order.subtotal)).toFixed(2)}<br />{order.currency}</td>
                                <td align="center"><DeliveryStatusFormat status={order.status} /></td>
                            </tr>
                        ))}
                        <tr key="final-totals">
                            <td colSpan="4" className={styles.totalCell}></td>
                            <td className={styles.totalCell}>Total</td>
                            <td className={styles.totalCell}>{Math.abs(subtotalSum).toFixed(2)}<br />KWD</td>
                            <td className={styles.totalCell}>{Math.abs(deliverySum).toFixed(2)}<br />KWD</td>
                            <td className={styles.totalCell}>{Math.abs(subtotalSum + deliverySum).toFixed(2)}<br />KWD</td>
                            <td colSpan="1" className={styles.totalCell}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style={{ marginBottom: '20px' }}></div>
            {console.log(orderCount, currentPage)}
            <Pagination
                currentPage={currentPage}
                setPage={setCurrentPage}
                totalPages={Math.ceil(orderCount / limit)}
            />

        </>
    )
}

export default OrderTable;