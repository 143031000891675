import { Table as AntTable, ConfigProvider, Tag } from "antd";

const Table = ({ columns, data, loading = false, pagination = true, title, sumColumns = [] }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#2c3e50", // Midnight Blue for header
            headerColor: "#ffffff", // White text for contrast
            footerBg: "#dfe6e9", // Light Slate Grey for footer
            footerColor: "#000000", // Black text for footer
          },
        },
      }}
    >
      <AntTable
        title={title ? () => title : undefined}
        columns={columns}
        dataSource={data}
        rowKey="id"
        bordered
        tableLayout="auto" // Ensures columns adjust to content
        scroll={{ x: "max-content" }} // Enables horizontal scrolling
        pagination={
          pagination
            ? {
                pageSize: 10,
                showTotal: (total, range) =>
                  `Showing (${range[0]}-${range[1]}) of ${total} entries`,
                defaultCurrent: 1,
                position: ["bottomCenter"],
                style: { marginTop: "20px", marginBottom: "0px" },
              }
            : false
        }
        size="middle"
        footer={() => <div style={{ height: "5px" }} />}
        loading={{ spinning: loading }}
        summary={
          sumColumns.length > 0
            ? (pageData) => {
                let totals = {};
                sumColumns.forEach((key) => {
                  totals[key] = pageData.reduce((sum, record) => sum + (Number(record[key]) || 0), 0);
                });
        
                // Find the index of the first column that needs summing
                const firstSumIndex = columns.findIndex(col => sumColumns.includes(col.dataIndex));
                const totalSpan = firstSumIndex > 0 ? firstSumIndex : 1; // "Total:" spans up to first sum column
        
                return (
                  <AntTable.Summary.Row>
                    {/* "Total:" cell should span all non-summed columns before the first sum column */}
                    <AntTable.Summary.Cell index={0} colSpan={totalSpan} align="center">
                      <strong>Total:</strong>
                    </AntTable.Summary.Cell>
        
                    {/* Render sum values for the specified columns */}
                    {columns.slice(totalSpan).map((col) =>
                      sumColumns.includes(col.dataIndex) ? (
                        <AntTable.Summary.Cell key={col.dataIndex} align="center">
                          <strong>{totals[col.dataIndex].toFixed(2)}</strong>
                        </AntTable.Summary.Cell>
                      ) : (
                        <AntTable.Summary.Cell key={col.dataIndex} />
                      )
                    )}
                  </AntTable.Summary.Row>
                );
              }
            : undefined
        }
        
      />
    </ConfigProvider>
  );
};

export default Table;
