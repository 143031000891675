import  { 
	CAPITAL_GOVERNORATE, 
	HAWALLI_GOVERNORATE, 
	MUBARAK_AL_KABEER_GOVERNORATE, 
	AHMADI_GOVERNORATE, 
	FARWANIYA_GOVERNORATE,
	JAHRA_GOVERNORATE,
} from './governorates.js';

const baseUrl = (slug) => {
	return `${process.env.REACT_APP_API_URL}${slug}`
}

export const API_URL = {
	LOGIN: baseUrl('/auth/login'),
	ACTIVATE_ACCOUNT: baseUrl('/auth/activate'),
	RESET_PASSWORD: baseUrl('/auth/reset-password'),
	UPDATE_PASSWORD: baseUrl('/auth/update-password'),
	ADMIN_UPDATE_PASSWORD: baseUrl('/auth/admin-update-password'),
	CREATE_USER: baseUrl('/auth/create-user'),
	CURRENT_USER: baseUrl('/users'),

	PRODUCTS: baseUrl('/items'),

	PICKUP_ADDRESSES: baseUrl('/places'),
	PICKUP_ADDRESSES_TOTAL: baseUrl('/places/total'),
	QUERY_PLACES: baseUrl('/places/autocomplete'),
	SAVE_COORDINATE_ADDRESS: baseUrl('/places/coordinates'),

	ORDERS: baseUrl('/orders'),
	ORDERS_TOTAL: baseUrl('/orders/total'),
	ORDERS_DRIVER_HISTORY: baseUrl('/orders/driver-history'),
	ORDERS_DRIVER_TOTAL: baseUrl('/orders/driver-total'),
	ORDERS_RESUME_ORDER: baseUrl('/orders/driver-resume-order'),
	ORDER_ITEMS: baseUrl('/orders/items'),

	CITIES: baseUrl('/admin/cities'),
	DELIVERY_COMPANY: baseUrl('/admin/deliveryCompanies'),
	TYPE_OF_PRODUCTS: baseUrl('/admin/products'),
	DELIVERY_ZONE: baseUrl('/admin/delivery-zone'),
	DELIVERY_FEE: baseUrl('/admin//delivery-fee'),
	BUSINESS_USERS: baseUrl('/admin/businesses'),
	TRACKING: baseUrl('/tracking'),

}


export const GET_FETCH_OPTIONS = () => {
	return {
		method: 'get',
		headers: { 
			'Authorization': `Bearer ${localStorage.getItem('token')}`,
			'Content-Type': 'application/json',
		},
		mode: 'cors',
	}
}

export const POST_FETCH_OPTIONS = (data) => {
	return {
		method: 'post',
		headers: { 
			'Authorization': `Bearer ${localStorage.getItem('token')}`,
			'Content-Type': 'application/json',
		},
		mode: 'cors',
		body: JSON.stringify(data)
	}
}

export const DELETE_FETCH_OPTIONS = () => {
	return {
		method: 'delete',
		headers: { 
			'Authorization': `Bearer ${localStorage.getItem('token')}`,
			'Content-Type': 'application/json',
		},
		mode: 'cors',
	}
}

export const USER_ROLE = {
	ADMIN: 'ADMIN',
	BUSINESS: 'BUSINESS',
	DRIVER: 'DRIVER',
}

export const USER_ROLE_TITLE = {
	[USER_ROLE.ADMIN]: 'Admin',
	[USER_ROLE.BUSINESS]: 'Business',
	[USER_ROLE.DRIVER]: 'Driver',
}


export const DELIVERY_STATUS = {
	CREATED: 'CREATED',
	ACCEPTED: 'ACCEPTED',
	ON_THE_WAY: 'DELIVERING',
	DELIVERED: 'DELIVERED',
	FAILED: 'FAILED',
	CANCELED: 'CANCELED',
}

export const DELIVERY_STATUS_TITLE = {
	[DELIVERY_STATUS.CREATED]: 'Pending',
	[DELIVERY_STATUS.ACCEPTED]: 'Accepted',
	[DELIVERY_STATUS.ON_THE_WAY]: 'Delivering (on way)',
	[DELIVERY_STATUS.DELIVERED]: 'Delivered',
	[DELIVERY_STATUS.FAILED]: 'Overdue',
	[DELIVERY_STATUS.CANCELED]: 'Canceled',
}

export const DELIVERY_STATUS_COLOR = {
	[DELIVERY_STATUS.CREATED]: 'black',
	[DELIVERY_STATUS.ACCEPTED]: 'blue',
	[DELIVERY_STATUS.ON_THE_WAY]: 'orange',
	[DELIVERY_STATUS.DELIVERED]: 'green',
	[DELIVERY_STATUS.FAILED]: 'red',
	[DELIVERY_STATUS.CANCELED]: 'red',
}

export const PAYMENT_METHOD = {
	ONLINE: 'ONLINE',
	CASH: 'CASH',
}

export const PAYMENT_METHOD_TITLE = {
	[PAYMENT_METHOD.ONLINE]: 'Credit Card/KNET (Online)',
	[PAYMENT_METHOD.CASH]: 'Cash on delivery',
}

export const CITIES = {
	...CAPITAL_GOVERNORATE,
	...HAWALLI_GOVERNORATE,
	...MUBARAK_AL_KABEER_GOVERNORATE,
	...AHMADI_GOVERNORATE,
	...FARWANIYA_GOVERNORATE,
	...JAHRA_GOVERNORATE,
}