import { useBusinessUsers } from "../swr/admin";
import { useState } from "react";
import { API_URL, POST_FETCH_OPTIONS } from "../common/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function BusinessUsers() {
  const { businesses, isLoading, isError } = useBusinessUsers();

  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  if (isLoading) return "Loading...";
  if (isError) return "Error...";

  const showModal = (userId) => {
    setShowUpdatePassword(true);
    setSelectedId(userId);
  };

  const confirmNewPassword = async () => {
    try {
      const result = await fetch(
        API_URL.ADMIN_UPDATE_PASSWORD,
        POST_FETCH_OPTIONS({ userId: selectedId, password: newPassword })
      ).then((res) => res.json());
      result.success ? toast.info(result.message) : toast.error(result.message);
    } catch (err) {
      toast.error("We couldn't update this password. Please try again.");
    }

    setShowUpdatePassword(false);
    setNewPassword("");
    setSelectedId(null);
  };

  return (
    <div className="table-container">
      <ToastContainer />
      <div className={`modal ${showUpdatePassword ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="box">
            <h2>Set New Password:</h2>
            <input
              className="input"
              type="password"
              onChange={(e) => setNewPassword(e.target?.value)}
              value={newPassword}
            />
            <button className="button" onClick={() => confirmNewPassword()}>
              Confirm
            </button>
          </div>
        </div>
        <button
          onClick={() => setShowUpdatePassword(false)}
          className="modal-close is-large"
          aria-label="close"
        ></button>
      </div>

      <table className="table is-bordered is-fullwidth is-centered">
        <thead>
          <tr>
            <th>Business</th>
            <th>Business phone number</th>
            <th>Type of product</th>
            <th>Instagram</th>
            <th>Email</th>
            <th>Email Verified</th>
          </tr>
        </thead>
        <tbody>
          {businesses?.map((user) => (
            <tr key={user?.id || ""}>
              <td>{user?.business_name ?? "N/A"}</td>
              <td>{user?.phone_numbers ?? "N/A"}</td>
              <td>{user?.product_type ?? "N/A"}</td>
              <td>{user?.instagram ?? "N/A"}</td>
              <td>{user?.email ?? "N/A"}</td>
              <td>{user?.is_verified ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
