import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { Calendar } from "lucide-react";

const CustomDatePicker = ({ selectedDate, onChange, maxDate, minDate, label }) => {
  return (
    <DatePicker
      selected={selectedDate} // The currently selected date
      onChange={onChange} // Sets the date
      dateFormat="dd-MM-yyyy" // Format the date
      todayButton="Today" // Displays the "Today" button
      minDate={minDate} // Min date selection
      maxDate={maxDate} // Max date selection
      showMonthDropdown // Show the month dropdown
      showYearDropdown // Show the year dropdown
      scrollableYearDropdown // Makes the year dropdown scrollable
      customInput={
        <TextField
          required
          fullWidth
          size="small"
          margin="dense"
          label={label} // The label for the date input
          slotProps={{
            inputLabel: { shrink: true }, // Ensures label stays above input
            input: {
              readOnly: true, // Makes the input read-only
              startAdornment: (
                <InputAdornment position="start">
                  <Calendar /> {/* Calendar icon */}
                </InputAdornment>
              ),
            },
          }}
        />
      }
    />
  );
};

export default CustomDatePicker;
