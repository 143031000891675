import { Link } from 'react-router-dom'
import { logout } from '../../common/helpers'
import AdminFooterNavigation from '../../../src/admin/pages/navigation/AdminPagesNavigation'
import { useState } from 'react'
import { API_URL, POST_FETCH_OPTIONS } from '../../common/constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DeliveryCompanies from '../../components/DeliveryCompanies'
import TypeOfProducts from '../../components/TypeOfProducts'

export default function Settings() {

	const [newPassword, setNewPassword] = useState('')

	const confirmNewPassword = async () => {
		if (!newPassword) {
			return
		}

		try {
			const result = await fetch(API_URL.ADMIN_UPDATE_PASSWORD, POST_FETCH_OPTIONS({ userId: localStorage.getItem('userId'), password: newPassword })).then(res => res.json())
			result.success ? toast.info(result.message) : toast.error(result.message)
		} catch (err) {
			toast.error('We couldn\'t update this password. Please try again.')
		}
	
		setNewPassword('')
	}

	return(
		<div className="container">
			<ToastContainer />
			<h1 className="is-size-1 has-text-centered my-5">Settings</h1>
			<AdminFooterNavigation activeTab={'admin-settings'} />
			<div className="has-text-centered">
				<Link onClick={() => logout()} to="/">Logout</Link>
			</div>

			<div>
				<input value={newPassword} onChange={(e) => setNewPassword(e.target?.value)} type="password" placeholder="set new password" /> 
				<button onClick={() => confirmNewPassword()}>update password</button>
			</div>

			<DeliveryCompanies />	
			<hr />

			<TypeOfProducts />
			<hr />


		</div>
	)
}