import useSWR from 'swr'
import { API_URL, GET_FETCH_OPTIONS } from '../common/constants'

const fetcher = ( url, query = '' ) => fetch(`${url}${query}`, GET_FETCH_OPTIONS()).then(res => res.json()) 

export function useCitiesList() {
	const { data, error } = useSWR(API_URL.CITIES, fetcher)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useDeliveryCompanies() {
	const { data, error } = useSWR(API_URL.DELIVERY_COMPANY, fetcher)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useTypeOfProducts() {
	const { data, error } = useSWR(API_URL.TYPE_OF_PRODUCTS, fetcher)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useDeliveryFees() {
	const { data, error } = useSWR(API_URL.DELIVERY_FEE, fetcher)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useDeliveryZones() {
	const { data, error } = useSWR(API_URL.DELIVERY_ZONE, fetcher)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export function useBusinessUsers() {
	const { data, error } = useSWR(API_URL.BUSINESS_USERS, fetcher)

	return {
		businesses: data?.businesses ? data?.businesses : []  ,
		isLoading: !error && !data,
		isError: error
	}
}
