const baseUrl = (slug) => {
  return `${process.env.REACT_APP_API_URL}${slug}`;
};

export const API_URLS = {
  FETCH_BUSINESSES_URL: baseUrl("/admin/businesses"),
  FETCH_DELIVERYCOMPANIES_URL: baseUrl("/admin/deliveryCompanies"),
  FETCH_ORDERS_URL: baseUrl("/admin/orders"),
  FETCH_PRICING_PLAN_DETAILS_URL: (id) => baseUrl(`/admin/deliveryCompany/${id}/pricingPlansdetails`),
  FETCH_ADMIN_BILLING_ANALYTICS_URL: baseUrl("/admin/billingAnalytics")
};
