import React from "react";
import { Grid2, FormControl, InputLabel, Select, MenuItem, InputAdornment } from "@mui/material";
import { Calendar } from "lucide-react";
import CustomDatePicker from "./CustomDatePicker";

const ALL_DATE_RANGES = {
  today: { value: "today", label: "Today" },
  yesterday: { value: "yesterday", label: "Yesterday" },
  last7days: { value: "last7days", label: "Last 7 Days" },
  last30days: { value: "last30days", label: "Last 30 Days" },
  thisweek: { value: "thisweek", label: "This Week" },
  thismonth: { value: "thismonth", label: "This Month" },
  lastmonth: { value: "lastmonth", label: "Last Month" },
  last3months: { value: "last3months", label: "Last 3 Months" },
  last6months: { value: "last6months", label: "Last 6 Months" },
  thisyear: { value: "thisyear", label: "This Year" },
  customrange: { value: "customrange", label: "Custom Range" },
};


const DateRangeField = ({
  selectedRange,
  setSelectedRange,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  allowedRanges,
}) => {
  // Filter date ranges based on allowed keys
  const dateRanges = allowedRanges.map((key) => ALL_DATE_RANGES[key]).filter(Boolean);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={selectedRange === "customrange" ? 8 : 12}>
        <FormControl fullWidth margin="dense" size="small">
          <InputLabel>Select Date Range</InputLabel>
          <Select
            label="Select Date Range"
            value={selectedRange}
            onChange={(e) => setSelectedRange(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <Calendar /> {/* Display the calendar icon */}
              </InputAdornment>
            }
          >
            {dateRanges.map((range) => (
              <MenuItem key={range.value} value={range.value}>
                {range.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid2>
      {selectedRange === "customrange" && (
        <>
          <Grid2 size={2}>
            <CustomDatePicker
              selectedDate={fromDate}
              onChange={setFromDate}
              maxDate={toDate || new Date()}
              label="From Date"
            />
          </Grid2>

          <Grid2 size={2}>
            <CustomDatePicker
              selectedDate={toDate}
              onChange={setToDate}
              minDate={fromDate}
              maxDate={new Date()}
              label="To Date"
            />
          </Grid2>
        </>
      )}
    </Grid2>
  );
};

export default DateRangeField;
