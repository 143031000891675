import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const DropDownField = ({
  label,
  value,
  onChange,
  options,
  includeAll = true,
  valueKey = "value",
  labelKey = "label",
  disabled = false,
}) => {
  return (
    <FormControl fullWidth margin="dense" size="small" disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={value} onChange={onChange}>
        {includeAll && (
          <MenuItem key="all" value="All">
            All
          </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDownField;
