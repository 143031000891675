// LoadingSpinner.js
import React from "react";
import styles from "../styles/LoadingSpinner.module.css"; // Import CSS file

const LoadingSpinner = () => {
  return (
    <div className={styles.loading_overlay}>
      <div className={styles.loading_spinner}>
        <div className={styles.spinner}></div>
        <p>Loading...</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
