import AdminPagesNavigation from "./navigation/AdminPagesNavigation";
import { PageLayout } from "../helpers/PageLayout";
import { Card, CardContent, Typography, Box, Divider } from "@mui/material";
import { useFetchBusinesses, useFetchAdminBillingAnalyticsPageData } from "../swr/hooks";
import { useState, useEffect } from "react";
import Button from "../helpers/Button";
import LoadingSpinner from "../helpers/LoadingSpinner";
import DateRangeField from "../helpers/DateRangeField";
import DropDownField from "../helpers/DropDownField";
import Table from "../helpers/Table";
import Icons from "../helpers/icons";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";

export default function AdminBillingAnalyticsPage() {
  const [selectedRange, setSelectedRange] = useState("thismonth");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedBusinessId, setSelectedBusinessId] = useState("");
  const [selectedDeliveryCompanyId, setSelectedDeliveryCompanyId] = useState("");
  const [selectedPricingPlanId, setSelectedPricingPlanId] = useState("All");
  const [selectedDeliveryZoneId, setSelectedDeliveryZoneId] = useState("All");

  const {
    data: businessesData,
    isLoading: fetchBusinessesLoading,
    isError: fetchBusinessesError,
  } = useFetchBusinesses();

  useEffect(() => {
    if (businessesData?.length > 0 && selectedBusinessId === "") {
      setSelectedBusinessId(businessesData[0].id);
    }
  }, [selectedBusinessId]);

  const handleClearFilters = () => {
    setSelectedRange("thismonth");
    setFromDate("");
    setToDate("");
    setSelectedBusinessId("");
  };

  const queryString = {
    selectedRange,
    fromDate,
    toDate,
    selectedBusinessId,
    selectedDeliveryCompanyId,
    selectedPricingPlanId: selectedPricingPlanId === "All" ? "" : selectedPricingPlanId,
    selectedDeliveryZoneId: selectedDeliveryZoneId === "All" ? "" : selectedDeliveryZoneId,
  };

  const {
    billingAnalytics,
    isLoading: billingAnalyticsLoading,
    isError: billingAnalyticsError,
  } = useFetchAdminBillingAnalyticsPageData(queryString);

  useEffect(() => {
    if (
      billingAnalytics?.distinctDeliveryCompanies?.length > 0 &&
      selectedDeliveryCompanyId === ""
    ) {
      setSelectedDeliveryCompanyId(billingAnalytics.distinctDeliveryCompanies[0].id);
    }
  }, [billingAnalytics, selectedDeliveryCompanyId]);

  const handleGraphFilters = () => {
    setSelectedDeliveryCompanyId("");
    setSelectedPricingPlanId("All");
    setSelectedDeliveryZoneId("All");
  };

  if (fetchBusinessesLoading) {
    return <LoadingSpinner />;
  }
  if (fetchBusinessesError || billingAnalyticsError) {
    const errorMessage = fetchBusinessesError
      ? "Businesses Data Error"
      : "Billing Analytics Data Error";

    return (
      <PageLayout
        title="Admin Billing Analytics"
        activeTab="admin-billing-analytics"
        NavigationComponent={AdminPagesNavigation}
      >
        <Card>
          <CardContent>
            <Typography variant="h6" component="h3">
              <strong>Error! </strong>
              <em>{errorMessage}</em>
            </Typography>
          </CardContent>
        </Card>
      </PageLayout>
    );
  }

  return (
    <>
      <PageLayout
        title="Admin Billing Analytics"
        activeTab="admin-billing-analytics"
        NavigationComponent={AdminPagesNavigation}
      >
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
                <strong>Billing Analytics </strong>
                <em>(Filters)</em>
              </Typography>
              <Button text="Clear" color={Button.buttonColors.RED} onClick={handleClearFilters} />
            </Box>
            <DateRangeField
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              allowedRanges={[
                "today",
                "thisweek",
                "thismonth",
                "lastmonth",
                "last3months",
                "last6months",
                "thisyear",
                "customrange",
              ]}
            />
            <DropDownField
              label="Select Business"
              value={selectedBusinessId}
              onChange={(e) => setSelectedBusinessId(e.target.value)}
              options={businessesData}
              valueKey="id"
              labelKey="business_name"
              includeAll={false}
            />
          </CardContent>
        </Card>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Box display="flex" alignItems="center">
              <Icons.StorefrontIcon sx={{ mr: 1, color: "black" }} />
              <Typography variant="h6" sx={{ mr: 1, fontWeight: "bold", color: "black" }}>
                {
                  businessesData.find((business) => business.id === selectedBusinessId)
                    ?.business_name
                }
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  color: "#0072b1",
                  backgroundColor: "#d0e8ff",
                  borderRadius: "20px",
                  padding: "1px 10px",
                  display: "inline-block",
                }}
              >
                <em>Business</em>
              </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
              <DropDownField
                label="Select Delivery Company"
                value={selectedDeliveryCompanyId}
                onChange={(e) => {
                  setSelectedDeliveryCompanyId(e.target.value);
                  setSelectedPricingPlanId("All");
                  setSelectedDeliveryZoneId("All");
                }}
                options={billingAnalytics.distinctDeliveryCompanies}
                valueKey="id"
                labelKey="name"
                includeAll={false}
              />
              <DropDownField
                label="Select Pricing Plan"
                value={selectedPricingPlanId}
                onChange={(e) => {
                  setSelectedPricingPlanId(e.target.value);
                  setSelectedDeliveryZoneId("All");
                }}
                options={billingAnalytics.distinctPricingPlans}
                valueKey="id"
                labelKey="name"
                includeAll={true}
              />
              <DropDownField
                label="Select Delivery Zone"
                value={selectedDeliveryZoneId}
                onChange={(e) => {
                  setSelectedDeliveryZoneId(e.target.value);
                }}
                options={billingAnalytics.distinctDeliveryZones}
                valueKey="id"
                labelKey="name"
                includeAll={true}
              />
              <Button text="Clear" color={Button.buttonColors.RED} onClick={handleGraphFilters} />
            </Box>
            <Divider sx={{ my: 2 }} />
            <DisplayTable />
            <Divider sx={{ my: 2 }} />
            <BillingAnalyticsChart data={billingAnalytics.orderSummary} />
          </CardContent>
        </Card>
      </PageLayout>
    </>
  );

  // ** Ant Design Table Component **
  function DisplayTable() {
    const tableColumns = [
      { title: "Business Name", dataIndex: "businessName", key: "businessName", align: "center" },
      {
        title: "Delivery Company",
        dataIndex: "deliveryCompany",
        key: "deliveryCompany",
        align: "center",
      },
      { title: "Pricing Plan", dataIndex: "pricingPlan", key: "pricingPlan", align: "center" },
      {
        title: "Delivery Zone",
        dataIndex: "deliveryZone",
        key: "deliveryZone",
        align: "center",
      },
      { title: "Pricing Tier", dataIndex: "pricingTier", key: "pricingTier", align: "center" },
      { title: "Orders", dataIndex: "orderCount", key: "orderCount", align: "center" },
      { title: "Price", dataIndex: "price", key: "price", align: "center" },
      { title: "Revenue", dataIndex: "revenue", key: "revenue", align: "center" },
    ];
    return (
      <Table
        columns={tableColumns}
        data={billingAnalytics.orderSummary}
        loading={billingAnalyticsLoading}
        //sumColumns={["orderCount", "price", "revenue"]}
        sumColumns={["orderCount", "revenue"]}
      />
    );
  }

  function BillingAnalyticsChart({ data }) {
    if (data) {
      // Group data by deliveryZone
      const groupedData = data?.reduce((acc, row) => {
        if (!acc[row.deliveryZone]) acc[row.deliveryZone] = [];
        acc[row.deliveryZone].push(row);
        return acc;
      }, {});

      return (
        <>
          {Object.entries(groupedData).map(([zone, zoneData]) => (
            <Card key={zone} sx={{ border: "2px solid #fafafa", borderRadius: "5px", mb: 3 }}>
              <CardContent>
                {/* Title */}
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: "#0072b1",
                      backgroundColor: "#d0e8ff",
                      borderRadius: "20px",
                      padding: "1px 10px",
                      display: "flex", // Enables flexbox
                      alignItems: "center", // Centers items vertically
                      gap: "5px", // Adds spacing between icon and text
                    }}
                  >
                    <Icons.StorefrontIcon />
                    <em>{zoneData[0].businessName}</em>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: "#d35400", // Deep orange text
                      backgroundColor: "#ffebcc", // Soft pastel orange background
                      borderRadius: "20px",
                      padding: "1px 10px",
                      display: "flex", // Enables flexbox
                      alignItems: "center", // Centers items vertically
                      gap: "5px", // Adds spacing between icon and text
                    }}
                  >
                    <Icons.DeliveryDiningIcon />
                    <em>{zoneData[0].deliveryCompany}</em>
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: "#00695c", // Dark teal text
                      backgroundColor: "#e0f2f1", // Light pastel teal background
                      borderRadius: "20px",
                      padding: "1px 10px",
                      display: "flex", // Enables flexbox
                      alignItems: "center", // Centers items vertically
                      gap: "5px", // Adds spacing between icon and text
                    }}
                  >
                    <Icons.MoneyBagIcon />
                    <em>{zoneData[0].pricingPlan}</em>
                  </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                      color: "#6a0dad", // Deep purple text
                      backgroundColor: "#e6ccff", // Soft pastel purple background
                      borderRadius: "20px",
                      padding: "1px 10px",
                      display: "flex", // Enables flexbox
                      alignItems: "center", // Centers items vertically
                      gap: "5px", // Adds spacing between icon and text
                    }}
                  >
                    <Icons.GlobeIcon />
                    <em>{zone}</em>
                  </Typography>
                </Box>
                {/* Bar Chart for this Zone */}
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart data={zoneData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <XAxis dataKey="pricingTier" />
                    <YAxis scale="log" domain={[1, "auto"]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="orderCount" fill="#8884d8" name="Orders" barSize={75} />
                    <Bar dataKey="revenue" fill="#82ca9d" name="Revenue" barSize={75} />
                  </BarChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          ))}
        </>
      );
    } else {
      return;
    }
  }
}
