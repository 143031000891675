import { useUser } from '../../swr/index'

export default function DeliveryCompany({ driverId, field }) {

	const { data, isLoading, isError } = useUser(driverId) 

	if (isLoading) return 'Loading...'
	if (isError) return "Error..."

	return(	
		<>
			{field === 'name' && <span>{data?.delivery_company ? data?.delivery_company.name : ''}</span>}
			{field === 'phone_number' && <span>{data?.delivery_company ? data?.delivery_company.phone_number : ''}</span>}
		</>
	)
}