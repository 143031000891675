import { Link } from "react-router-dom";

export default function AdminPagesNavigation({ activeTab }) {
  return (
    <div className="tabs is-toggle is-fullwidth">
      <ul>
        <li className={activeTab === "admin-dashboard" ? "is-active" : ""}>
          <Link to="/admin/dashboard"><h3>Admin Dashboard</h3></Link>
        </li>
        <li className={activeTab === "admin-billing-analytics" ? "is-active" : ""}>
          <Link to="/admin/billing-analytics"><h3>Admin Billing Analytics</h3></Link>
        </li>
        <li className={activeTab === "admin-businesses" ? "is-active" : ""}>
          <Link to="/admin/businesses"><h3>Businesses</h3></Link>
        </li>
        <li className={activeTab === "admin-tracking" ? "is-active" : ""}>
          <Link to="/admin/tracking"><h3>Realtime Bookings & Tracking</h3></Link>
        </li>
        <li className={activeTab === "admin-fees" ? "is-active" : ""}>
          <Link to="/admin/fees"><h3>Delivery Fees</h3></Link>
        </li>
        <li className={activeTab === "admin-settings" ? "is-active" : ""}>
          <Link to="/admin/settings"><h3>Settings</h3></Link>
        </li>
      </ul>
    </div>
  );
}
