import React from "react";
import styles from "../styles/Button.module.css";

const buttonColors = {
  RED: "red",
  BLUE: "blue",
  TEAL: "teal",
  GREY: "grey",
};

const Button = ({ text, onClick, color = buttonColors.GREY, icon: Icon, ...props }) => {
  return (
    <button className={`${styles.button_base} ${styles[`button_${color}`]}`} onClick={onClick} {...props}>
      {Icon && <Icon className={styles.icon_spacing} />}
      {text}
    </button>
  );
};

Button.buttonColors = buttonColors;
export default Button;
