import axios from "axios";

// GET_API_CALL function with axios
export const GET_API_CALL = (url, params = {}) => {
  return axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      params,
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching data:", error);
      throw error;
    });
};
