import React from 'react';

export const Logo = () => {
    return (
        <img
            className="logo"
            width="90px"
            src={process.env.PUBLIC_URL + '/logo-purple.png'}
            alt="Logo"
            style={{
                position: 'absolute',
                top: '25px',   // Adjust this as needed for the space from the top
                left: '25px',  // Adjust this as needed for the space from the left
                marginTop: '0',  // Optional to remove any extra margin
                marginLeft: '0'  // Optional to remove any extra margin
            }}
        />
    );
};
