import { useNavigate, useParams } from "react-router-dom";
import { API_URL, USER_ROLE, POST_FETCH_OPTIONS, USER_ROLE_TITLE } from "../../common/constants";
import { useState } from "react";
import SelectCompany from "../../components/small/SelectDeliveryCompany";
import { useDeliveryCompanies } from "../../swr/admin";

export default function SignUp() {
  const navigate = useNavigate();
  const { role } = useParams();

  const [errorMessage, setErrorMessage] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [driverName, setDriverName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [businessName, setBusinessName] = useState("");

  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [civilIdNumber, setCivilIdNumber] = useState("");

  const { data: deliveryCompanies } = useDeliveryCompanies();

  const handleSignUp = (e) => {
    e.preventDefault();
    setErrorMessage("");
    const formData = new FormData(e.target);
    let data = {};

    let keys = [];
    if (role.toUpperCase() === USER_ROLE.BUSINESS) {
      data = {
        email,
        password,
        phone_numbers: [phoneNumber],
        business_name: businessName,
      };
    } else if (role.toUpperCase() === USER_ROLE.DRIVER) {
      const selectedDeliveryCompany = deliveryCompanies.deliveryCompanies.find(
        (com) => com.id === deliveryCompany
      );
	  console.log("deliveryCompany",deliveryCompany)
	  console.log("selectedDeliveryCompany",selectedDeliveryCompany)
      data = {
        email,
        password,
        phone_numbers: [phoneNumber],
        driver_name: driverName,
        civil_id: civilIdNumber,
        delivery_company: deliveryCompany,
        deliveryCompanyId: selectedDeliveryCompany.id,
      };
    }

    data.role = role.toUpperCase();

    if (validateForm(data)) {
      fetch(API_URL.CREATE_USER, POST_FETCH_OPTIONS(data))
        .then((res) => res.json())
        .then((data) => {
          data.id ? navigate(`/${role}?verify=true`) : setErrorMessage(data.message);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    } else {
      setErrorMessage("Please fill out the entire form.");
    }
    return;
  };

  const validateForm = (data) => {
    if (
      data.role === USER_ROLE.BUSINESS &&
      data.email &&
      data.password &&
      data.business_name &&
      data.phone_numbers
    ) {
      return true;
    } else if (
      data.role === USER_ROLE.DRIVER &&
      data.email &&
      data.password &&
      data.phone_numbers &&
      data.driver_name &&
      data.civil_id &&
      data.delivery_company
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className="my-6">
      <div className="max-width-md mx-auto">
        <h1 className="is-size-1 has-text-centered my-4">
          {USER_ROLE_TITLE[role.toUpperCase()]} Sign up
        </h1>

        <form onSubmit={handleSignUp}>
          {role.toUpperCase() === USER_ROLE.DRIVER && (
            <>
              <div className="mb-4">
                <label className="label">Select Delivery Company</label>
                <SelectCompany
                  onSelect={(company) => setDeliveryCompany(company)}
                  value={deliveryCompany}
                />
              </div>

              <div>
                <label className="label">
                  Email <span className="has-text-danger">*</span>
                </label>
                <input
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input mb-4"
                  type="email"
                />

                <label className="label">
                  Password <span className="has-text-danger">*</span>
                </label>
                <input
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input mb-4"
                  type="password"
                />

                <label className="label">
                  Driver name <span className="has-text-danger">*</span>
                </label>
                <input
                  name="driver_name"
                  value={driverName}
                  onChange={(e) => setDriverName(e.target.value)}
                  className="input mb-4"
                  type="text"
                />

                <label className="label">
                  Phone number <span className="has-text-danger">*</span>
                </label>
                <input
                  name="phone"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="input mb-4"
                  type="tel"
                />

                <label className="label">
                  Civil ID Number <span className="has-text-danger">*</span>
                </label>
                <input
                  name="civil_id"
                  value={civilIdNumber}
                  onChange={(e) => setCivilIdNumber(e.target.value)}
                  className="input mb-4"
                  type="number"
                />
              </div>
            </>
          )}

          {role.toUpperCase() === USER_ROLE.BUSINESS && (
            <div>
              <label className="label">
                Email <span className="has-text-danger">*</span>
              </label>
              <input
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input mb-4"
                type="email"
              />

              <label className="label">
                Password <span className="has-text-danger">*</span>
              </label>
              <input
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input mb-4"
                type="password"
              />

              <label className="label">
                Business name <span className="has-text-danger">*</span>
              </label>
              <input
                name="business_name"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                className="input mb-4"
                type="text"
              />

              <label className="label">
                Phone number <span className="has-text-danger">*</span>
              </label>
              <input
                name="phone"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="input mb-4"
                type="tel"
              />
            </div>
          )}

          {errorMessage && <p className="has-text-danger mb-2">{errorMessage}</p>}
          <input
            className="button is-fullwidth has-background-grey-light"
            type="submit"
            value="Sign up"
          />
        </form>
      </div>
    </div>
  );
}
