import AdminFooterNavigation from '../../../src/admin/pages/navigation/AdminPagesNavigation'
import BusinessUsers from '../../components/BusinessUsers'

export default function AdminCompanies() {
	return (
		<div className="container">
			<h1 className="is-size-1 has-text-centered my-5">Businesses</h1>
			<AdminFooterNavigation activeTab={'admin-businesses'} />
			<BusinessUsers />
		</div>
	)
}