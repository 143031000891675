import useSWR from "swr";
import { API_URLS } from "./urls";
import { GET_API_CALL } from "./fetcher";
import {
  startOfDay,
  endOfDay,
  subDays,
  startOfWeek,
  endOfWeek,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
} from "date-fns";

export function useFetchBusinesses() {
  const { data, error } = useSWR(API_URLS.FETCH_BUSINESSES_URL, GET_API_CALL);
  return {
    data: data && data.businesses ? data.businesses : [],
    isLoading: !error && !data, // Loading state
    isError: error, // Error state
  };
}

export function useFetchDeliveryCompanies() {
  const { data, error } = useSWR(API_URLS.FETCH_DELIVERYCOMPANIES_URL, GET_API_CALL);
  return {
    data: data && data.deliveryCompanies ? data.deliveryCompanies : [],
    isLoading: !error && !data, // Loading state
    isError: error, // Error state
  };
}

export function useFetchAdminDashboardPageOrders(queryParams) {
  const { selectedRange, fromDate, toDate, selectedBusinessId, selectedCompanyId, searchText } =
    queryParams;

  let startDate = "";
  let endDate = "";
  const today = new Date(new Date().toISOString().split("T")[0]);

  switch (selectedRange) {
    case "today":
      startDate = startOfDay(today).toISOString();
      endDate = endOfDay(today).toISOString();
      break;

    case "yesterday":
      const yesterday = subDays(today, 1);
      startDate = startOfDay(yesterday).toISOString();
      endDate = endOfDay(yesterday).toISOString();
      break;

    case "last7days":
      startDate = startOfDay(subDays(today, 6)).toISOString();
      endDate = endOfDay(today).toISOString();
      break;

    case "last30days":
      startDate = startOfDay(subDays(today, 29)).toISOString();
      endDate = endOfDay(today).toISOString();
      break;

    case "thismonth":
      startDate = startOfDay(startOfMonth(today)).toISOString();
      endDate = endOfDay(endOfMonth(today)).toISOString();
      break;

    case "lastmonth":
      const lastMonth = subMonths(today, 1);
      startDate = startOfDay(startOfMonth(lastMonth)).toISOString();
      endDate = endOfDay(endOfMonth(lastMonth)).toISOString();
      break;

    case "customrange":
      startDate = fromDate ? startOfDay(new Date(fromDate)).toISOString() : "";
      endDate = toDate ? endOfDay(new Date(toDate)).toISOString() : "";
      break;

    default:
      startDate = "";
      endDate = "";
  }

  if (startDate) {
    startDate = new Date(new Date(startDate).getTime() - today.getTimezoneOffset() * 60000);
  }

  if (endDate) {
    endDate = new Date(new Date(endDate).getTime() - today.getTimezoneOffset() * 60000);
  }

  const formattedQueryParams = {
    startDate,
    endDate,
    selectedBusinessId: selectedBusinessId === "All" ? "" : selectedBusinessId,
    selectedCompanyId: selectedCompanyId === "All" ? "" : selectedCompanyId,
    searchText,
  };
  const { data, error } = useSWR([API_URLS.FETCH_ORDERS_URL, formattedQueryParams], GET_API_CALL);
  return {
    orders: data && data.orders ? data.orders : [],
    orderCount: data && data.orderCount ? data.orderCount : 0,
    isLoading: !error && !data, // Loading state
    isError: error, // Error state
  };
}

// export function useFetchPricingPlanDetails(companyId) {
//   // To get pricingplans, zones, tiers of selected business.
//   const { data, error } = useSWR(API_URLS.FETCH_PRICING_PLAN_DETAILS_URL(companyId), GET_API_CALL);
//   return {
//     data: data && data.pricingPlanDetails ? data.pricingPlanDetails : [],
//     isLoading: !error && !data, // Loading state
//     isError: error, // Error state
//   };
// }

export function useFetchAdminBillingAnalyticsPageData(queryParams) {
  const {
    selectedRange,
    fromDate,
    toDate,
    selectedBusinessId,
    selectedDeliveryCompanyId,
    selectedPricingPlanId,
    selectedDeliveryZoneId
  } = queryParams;

  let startDate = "";
  let endDate = "";
  const today = new Date(new Date().toISOString().split("T")[0]);

  switch (selectedRange) {
    case "today":
      startDate = startOfDay(today).toISOString();
      endDate = endOfDay(today).toISOString();
      break;

    case "thisweek":
      startDate = startOfDay(startOfWeek(today)).toISOString();
      endDate = endOfDay(endOfWeek(today)).toISOString();
      break;

    case "thismonth":
      startDate = startOfDay(startOfMonth(today)).toISOString();
      endDate = endOfDay(endOfMonth(today)).toISOString();
      break;

    case "lastmonth":
      const lastMonth = subMonths(today, 1);
      startDate = startOfDay(startOfMonth(lastMonth)).toISOString();
      endDate = endOfDay(endOfMonth(lastMonth)).toISOString();
      break;

    case "last3months":
      startDate = startOfDay(subMonths(today, 3)).toISOString();
      endDate = endOfDay(today).toISOString();
      break;

    case "last6months":
      startDate = startOfDay(subMonths(today, 6)).toISOString();
      endDate = endOfDay(today).toISOString();
      break;

    case "thisyear":
      startDate = startOfDay(startOfYear(today)).toISOString();
      endDate = endOfDay(endOfYear(today)).toISOString();
      break;

    case "customrange":
      startDate = fromDate ? startOfDay(new Date(fromDate)).toISOString() : "";
      endDate = toDate ? endOfDay(new Date(toDate)).toISOString() : "";
      break;

    default:
      startDate = "";
      endDate = "";
  }

  if (startDate) {
    startDate = new Date(new Date(startDate).getTime() - today.getTimezoneOffset() * 60000);
  }

  if (endDate) {
    endDate = new Date(new Date(endDate).getTime() - today.getTimezoneOffset() * 60000);
  }

  const formattedQueryParams = {
    startDate,
    endDate,
    selectedBusinessId,
    selectedDeliveryCompanyId,
    selectedPricingPlanId,
    selectedDeliveryZoneId
  };

  const { data, error } = useSWR(
    [API_URLS.FETCH_ADMIN_BILLING_ANALYTICS_URL, formattedQueryParams],
    GET_API_CALL
  );
  return {
    billingAnalytics: data ? data.billingAnalytics : [],
    isLoading: !error && !data, // Loading state
    isError: error, // Error state
  };
}
